import moment from 'moment';
import { Audio } from 'react-loader-spinner';
import { useReportUsers } from '../services/Query';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { CSVLink } from 'react-csv';
import { RegenerateLetterConfirm } from '../components/RegenerateLetterConfirm';
import { useMemo, useRef, useState } from 'react';
import { FinancialAid } from '../models';
import { Student } from '../models/student';

const LoadingBox = () => {
  return (
    <div className="d-flex justify-content-center mb-2">
      <Audio
        height="100"
        width="100"
        color="#4fa94d"
        ariaLabel="audio-loading"
        wrapperStyle={{}}
        wrapperClass="wrapper-class"
        visible={true}
      />
    </div>
  );
};

export const Reports = () => {
  const {
    data: reports,
    isLoading,
    isRefetching,
    refetch: reloadReports,
  } = useReportUsers();
  const activatedRef = useRef<HTMLTableElement>(null);
  const submittedRef = useRef<HTMLTableElement>(null);
  const [activePage, setActivePage] = useState(1);
  const [noActivePage, setnoActivePage] = useState(1);
  const [submittedPage, setSubmittedPage] = useState(1);
  const [curUserId, setCurUserId] = useState<string | undefined>(undefined);
  const [curStudent, setCurStudent] = useState<Student | undefined>(undefined);
  const [curFinancialAid, setCurFinancialAid] = useState<
    FinancialAid | undefined
  >(undefined);
  const [showLetterModal, setShowLetterModal] = useState(false);
  const rows = 20;
  const gpa_list = [
    { label: 'GPA 4.00 or Higher', value: '25000' },
    { label: 'GPA 3.99-3.80', value: '25000' },
    { label: 'GPA 3.50-3.79', value: '24000' },
    { label: 'GPA 3.25-3.49', value: '23000' },
    { label: 'GPA 3.00-3.24', value: '22000' },
    { label: 'GPA 2.75-2.99', value: '22000' },
    { label: 'GPA 2.50-2.74', value: '17000' },
    { label: 'GPA 2.25-2.49', value: '17000' },
    { label: 'GPA 2.00-2.24', value: '17000' },
    { label: 'GPA Below 2.00', value: '0' },
  ];

  const satList = [
    '',
    '800-890',
    '900-990',
    '1000-1090',
    '1100-1190',
    '1200-1290',
    '1300-1390',
    '1400-1490',
    '1500-1590',
    '1600',
    'Not taken',
  ];

  const noActivateTotalPage = useMemo(() => {
    if (reports?.noActivatedUsers.length) {
      return Math.ceil(reports.noActivatedUsers.length / rows);
    }
    return 0;
  }, [reports]);
  const getParent = (sId: string) => {
    if (reports?.parents) {
      return reports.parents.find((it) => it.createdByStudentId == sId);
    }
    return undefined;
  };
  const getFinancialObj = (sId: string) => {
    if (reports?.financialAids) {
      return reports.financialAids.find((it) => it._Student.id == sId);
    }
    return undefined;
  };
  const noactivateRows = useMemo(() => {
    if (reports?.noActivatedUsers.length) {
      const sPos = (noActivePage - 1) * rows;
      return reports.noActivatedUsers.slice(sPos, sPos + rows);
    }
    return [];
  }, [reports, noActivePage]);
  const submittedTotalPage = useMemo(() => {
    if (reports?.submittedUsers.length) {
      return Math.ceil(
        reports.submittedUsers.filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            2024
        ).length / rows
      );
    }
    return 0;
  }, [reports]);
  const submittedRows = useMemo(() => {
    if (reports?.submittedUsers.length) {
      const sPos = (submittedPage - 1) * rows;
      return reports.submittedUsers
        .filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            2024
        )
        .slice(sPos, sPos + rows);
    }
    return [];
  }, [reports, submittedPage]);
  const activateTotalPage = useMemo(() => {
    if (reports?.activatedUsers.length) {
      return Math.ceil(reports.activatedUsers.length / rows);
    }
    return 0;
  }, [reports]);
  const activateRows = useMemo(() => {
    if (reports?.activatedUsers.length) {
      const sPos = (activePage - 1) * rows;

      let simplearray = reports?.submittedUsers
        .filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            2024
        )
        .map((itemY) => {
          return itemY._Student.email;
        });

      return reports.activatedUsers
        .filter((itemX) => !simplearray.includes(itemX.email))
        .slice(sPos, sPos + rows);
    }
    return [];
  }, [reports, activePage]);
  const totalcountActivatedUsers = useMemo(() => {
    if (reports?.activatedUsers.length) {
      let simplearray = reports?.submittedUsers
        .filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            2024
        )
        .map((itemY) => {
          return itemY._Student.email;
        });
      return reports?.activatedUsers.filter(
        (itemX) => !simplearray.includes(itemX.email)
      ).length;
    }
    return [];
  }, [reports, activePage]);
  const excelActivateData = useMemo(() => {
    if (reports?.activatedUsers) {
      let simplearray = reports?.submittedUsers
        .filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            2024
        )
        .map((itemY) => {
          return itemY._Student.email;
        });

      return reports?.activatedUsers
        .filter((itemX) => !simplearray.includes(itemX.email))
        .map((_it) => {
          const parentObj = getParent(_it.id);
          return {
            firstname: _it.firstname,
            lastname: _it.lastName,
            email: _it.email,
            parent_firstName: parentObj?.firstName || '',
            parent_lastName: parentObj?.lastName || '',
            parent_email: parentObj?.email || '',
            createdDate: moment(_it.createdDate).format('l'),
          };
        });
    }
    return [];
  }, [reports]);
  const excelSubmittedData = useMemo(() => {
    if (reports?.submittedUsers) {
      return reports.submittedUsers
        .filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            moment().toDate().getFullYear()
        )
        .map((_it) => {
          return {
            firstname: _it._Student.firstname,
            lastname: _it._Student.lastName,
            email: _it._Student.email,
            year: _it.year,
            gpa: _it.gpa != null ? gpa_list[_it.gpa].label : '',
            sai: _it.sai,
            pellgrant: _it.pellGrant == 1 ? 'Yes' : 'No',
            sat: _it.sat_score != null ? satList[_it.sat_score] : '',
            act_score: _it.act_score,
            varsity_athletic: _it.varsity_athletic,
            student_activities: _it.student_activities,
            subsidized_direct_loan: _it.subsidized_direct_loan,
            unsubsidized_direct_loan: _it.unsubsidized_direct_loan,
            intend_major: _it.intend_major,
            createdDate: moment(_it._Student.createdDate).format('l'),
            submittedDate: moment(_it.letter_send_date).format('l'),
          };
        });
    }
    return [];
  }, [reports]);
  const excelnoActivatedData = useMemo(() => {
    if (reports?.noActivatedUsers) {
      return reports.noActivatedUsers.map((_it) => {
        return {
          firstname: _it.firstname,
          lastname: _it.lastName,
          email: _it.email,
          createdDate: moment(_it.createdDate).format('l'),
        };
      });
    }
    return [];
  }, [reports]);
  return (
    <Tabs defaultActiveKey="activated">
      <Tab eventKey="activated" title="Not-submitted Users">
        {isLoading || isRefetching ? (
          <LoadingBox />
        ) : (
          <>
            <div className="d-flex justify-content-between mb-1">
              <div>
                <h6> Total Not-submitted Users: {totalcountActivatedUsers}</h6>
                <p>Not Submitted Users = started the process but have not completed it.</p>
              </div>
              <div>
                <CSVLink
                  data={excelActivateData}
                  filename={`Activated_Users_Report(${moment().format('L')})`}
                  className="btn btn-success fw-bold"
                  target="_blank"
                  onClick={(event, done) => {
                    alert('Exported successfully!');
                  }}
                >
                  Export Data to CSV
                </CSVLink>
              </div>
            </div>

            <table className="table table-bordered" ref={activatedRef}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>P_first name</th>
                  <th>P_last name</th>
                  <th>P_email</th>
                  <th>FSS Info</th>
                  <th>Student Info</th>
                  <th>Parent Info</th>
                  <th>Activated Date</th>
                </tr>
              </thead>
              <tbody>
                {activateRows.map((it) => (
                  <tr key={it.id}>
                    <td>{it.firstname}</td>
                    <td>{it.lastName}</td>
                    <td>{it.email}</td>
                    {getParent(it.id) ? (
                      <>
                        <td>{getParent(it.id)?.firstName}</td>
                        <td>{getParent(it.id)?.lastName}</td>
                        <td>{getParent(it.id)?.email}</td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                    <td>{!!getFinancialObj(it.id)?.sai ? '✓' : '✕'}</td>
                    <td>
                      {getFinancialObj(it.id)?.gpa != undefined ? '✓' : '✕'}
                    </td>
                    <td>{!!getParent(it.id) ? '✓' : '✕'}</td>
                    <td>{moment(it.createdDate).format('l')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex">
              <Button
                variant="secondary"
                size={'sm'}
                disabled={activePage == 1}
                onClick={() => setActivePage(activePage - 1)}
                className="me-2"
              >
                Prev
              </Button>
              <Button
                variant="secondary"
                size={'sm'}
                disabled={activateTotalPage == activePage}
                onClick={() => setActivePage((prev) => ++prev)}
              >
                Next
              </Button>
            </div>
          </>
        )}
      </Tab>
      <Tab eventKey="submitted" title="Submitted Users">
        {isLoading || isRefetching ? (
          <LoadingBox />
        ) : (
          <>
            <div className="d-flex justify-content-between mb-1">
              <div>
                <h6>
                  Total Submitted Users:{' '}
                  {
                    reports?.submittedUsers.filter(
                      (it) =>
                        moment(it.letter_send_date).toDate().getFullYear() >=
                        2024
                    ).length
                  }
                </h6>
                <p>Submitted Users = completed process, and an email was sent to AMC.</p>
              </div>
              {/* <DownloadTableExcel
                filename="submitted_users"
                sheet="submitted users"
                currentTableRef={submittedRef.current}
              >
                <Button variant="success" className=" fw-bold">
                  Export xls file
                </Button>
              </DownloadTableExcel> */}
              <div>
                <CSVLink
                  data={excelSubmittedData}
                  filename={`Submitted_Users_Report(${moment().format('L')})`}
                  className="btn btn-success fw-bold"
                  target="_blank"
                  onClick={(event, done) => {
                    alert('Exported successfully!');
                  }}
                >
                  Export Data to CSV
                </CSVLink>
              </div>
            </div>
            <table className="table table-bordered" ref={submittedRef}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Created Date</th>
                  <th>Submitted Date</th>
                </tr>
              </thead>
              <tbody>
                {submittedRows.map((it) => (
                  <tr key={it.id}>
                    <td>{it._Student.firstname}</td>
                    <td>{it._Student.lastName}</td>
                    <td>{it._Student.email}</td>
                    <td>{moment(it._Student.createdDate).format('l')}</td>
                    <td>{moment(it.letter_send_date).format('l')}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          if (it._Student) {
                            setShowLetterModal(true);
                            setCurUserId(it?._Student.id);
                            setCurStudent(it._Student);
                            setCurFinancialAid(it);
                          }
                        }}
                      >
                        Regenerate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex">
              <Button
                variant="secondary"
                disabled={submittedPage == 1}
                onClick={() => setSubmittedPage(submittedPage - 1)}
                size={'sm'}
                className="me-2"
              >
                Prev
              </Button>
              <Button
                variant="secondary"
                disabled={submittedTotalPage == submittedPage}
                size={'sm'}
                onClick={() => setSubmittedPage(submittedPage + 1)}
              >
                Next
              </Button>
            </div>
            {showLetterModal && curUserId && curStudent && curFinancialAid && (
              // eslint-disable-next-line react/jsx-no-undef
              <RegenerateLetterConfirm
                show={showLetterModal}
                userObj={curStudent}
                onDiscard={() => {
                  setShowLetterModal(false);
                }}
                onOk={() => {
                  setShowLetterModal(false);
                  reloadReports();
                }}
                userId={curUserId}
              />
            )}
          </>
        )}
      </Tab>
      <Tab eventKey="noActivated" title="No Login Users">
        {isLoading || isRefetching ? (
          <LoadingBox />
        ) : (
          <>
            <div className="d-flex justify-content-between mb-1">
              <div>
                <h6>
                  Total No Login Users: {reports?.noActivatedUsers.length}
                </h6>
                <p>No Login Users = confirmed but have not started the process.</p>
              </div>
              {/* <DownloadTableExcel
                filename="activated_users"
                sheet="activated users"
                currentTableRef={activatedRef.current}
              >
                <Button variant="success" className=" fw-bold">
                  Export xls file
                </Button>
              </DownloadTableExcel> */}
              <div>
                <CSVLink
                  data={excelnoActivatedData}
                  filename={`Not-Activated_Users_Report(${moment().format(
                    'L'
                  )})`}
                  className="btn btn-success fw-bold"
                  target="_blank"
                  onClick={(event, done) => {
                    alert('Exported successfully!');
                  }}
                >
                  Export Data to CSV
                </CSVLink>
              </div>
            </div>

            <table className="table table-bordered" ref={activatedRef}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {reports?.noActivatedUsers.map((it) => (
                  <tr key={it.id}>
                    <td>{it.firstname}</td>
                    <td>{it.lastName}</td>
                    <td>{it.email}</td>
                    <td>{moment(it.createdDate).format('l')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Tab>
    </Tabs>
  );
};
